
import {Component, Vue} from 'vue-property-decorator';
import CompaniesList from '@/components/AdminCompanies/CompaniesList.vue';
import ICompany from '@/types/ICompany';
import CompaniesService from '@/services/CompaniesService';
import ICompaniesFilters from '@/types/ICompaniesFilters';
import {resolveError} from '@/utils/notifications';
import CompaniesFilters from '@/components/AdminCompanies/CompaniesFilters.vue';
import {IPagination} from '@/types/IPagination';

@Component({
  name: 'Companies',
  components: {
    CompaniesFilters,
    CompaniesList,
  },
})
export default class Companies extends Vue {
  companies: ICompany[] = [];
  loading: boolean = false;
  filters: ICompaniesFilters = {
    city: '',
    companyName: '',
    countryId: null,
    dateFrom: '',
    dateTo: '',
    postalCode: '',
    vatin: '',
  };

  pagination: IPagination = {
    size: 10,
    totalElements: 0,
    page: 1,
  };

  async fetchData() {
    try {
      this.loading = true;
      const {
        companies,
        totalElements,
      } = await CompaniesService.getAll({...this.filters, ...this.pagination});
      this.companies = companies;
      this.pagination.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  onFiltersChanged(filters: ICompaniesFilters) {
    this.filters = {...filters};
    this.fetchData();
  }
}
