
import {Component, Emit, Vue} from 'vue-property-decorator';
import ICompaniesFilters from '@/types/ICompaniesFilters';
import DebounceInput from '@/components/DebounceInput.vue';
import ICountry from '@/types/ICountry';
import AuthService from '@/services/AuthService';
import {resolveError} from '@/utils/notifications';
import DateRangePicker from '@/components/DateRangePicker.vue';

@Component({
  name: 'CompaniesFilters',
  components: {
    DateRangePicker,
    DebounceInput,
  },
})
export default class CompaniesFilters extends Vue {
  filters: ICompaniesFilters = {
    city: '',
    companyName: '',
    countryId: null,
    dateFrom: '',
    dateTo: '',
    postalCode: '',
    vatin: '',
  };
  // dateRange: (string | null)[] = [null, null]

  countries: ICountry[] = [];

  get dateRange(): (string | null)[] {
    if (this.filters.dateTo) {
      return [this.filters.dateFrom, this.filters.dateTo];
    } else if (this.filters.dateFrom) {
      return [this.filters.dateFrom];
    } else {
      return [];
    }
  }

  set dateRange(dateRange: (string | null)[]) {
    if (dateRange[0] && dateRange[1] && dateRange[1] < dateRange[0]) {
      this.filters.dateFrom = dateRange[1];
      this.filters.dateTo = dateRange[0];
    } else {
      this.filters.dateFrom = dateRange[0];
      this.filters.dateTo = dateRange[1];
    }
  }

  created() {
    this.fetchCountries();
  }

  async fetchCountries() {
    try {
      this.countries = await AuthService.getRegisterForm();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  @Emit('changed')
  changed() {
    Object.keys(this.filters).forEach((key) => {
      // @ts-ignore
      this.filters[key] = this.filters[key] || '';
    });
    return this.filters;
  }
}
